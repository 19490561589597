<template>
  <button button-filter @click="$emit('click')">
    <slot></slot>
  </button>
</template>
<script>
export default {};
</script>
<style lang="less">
@import '~@/less/proj.less';

[button-filter] {.p(0, 32);.h(48);.br(4);
  &:not(.active) {.bgc(#fff);.c(#bf79ff);.fs(14);.lh(14);.pointer;
    &:hover {.c(#ebebf0);.bgc(#bf79ff);}
  }
  &.active {.bgc(#8300ff);.c(#fff);
    span {.c(#debaff)}
    em {.c(#fff)}
    &:hover {.bgc(#bf79ff);.c(#debaff);}
  }
  @media (@tp-down) {
    height: 40px !important;
    padding: 0 12px !important;
    font-size: 12px !important;
  }
}
</style>
