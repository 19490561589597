<template>
  <component :is="tag">
    <slot :champText="champText" :champImageUrl="champImageUrl">{{ champText }}</slot>
  </component>
</template>
<script>
export default {
  data: () => ({
    champData: null,
  }),
  props: {
    champCode: {
      type: String,
      default: ''
    },
    tag: {
      type: [String, Object],
      default: 'span'
    }
  },
  computed: {
    cd() {
      return /** @type{LolChamp}*/ this.champData;
    },
    champText() {
      return this.cd?.koName || '';
    },
    champImageUrl() {
      return this.cd?.imageUrl || '';
    }
  },
  watch: {
    champCode: {
      handler: 'getChampText'
    }
  },
  methods: {
    async getChampText() {
      if (this.champCode === '') return;
      this.champData = await this.$services.coaching.getChampByCode(this.champCode);
    }
  },
  beforeMount() {
    this.getChampText();
  }
};
</script>