<template>
  <cdn-img :src="champIcon" v-if="c.groupName === 'LOL_CHAMPION'" class="champ" category />
  <div v-else-if="c.groupName === 'LOL_LANE'" class="lane" category>
    <component :is="laneImageComponent" />
  </div>
  <div v-else class="lane" category>
    <p>?</p>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import {
  SvgLaneAll,
  SvgLaneJungle,
  SvgLaneBottom,
  SvgLaneMid,
  SvgLaneTop,
  SvgLaneSupport,
} from '@/views/graphics/lol-lane/LazyLoadings';

export default {
  components: { CdnImg, SvgLaneAll, SvgLaneJungle, SvgLaneBottom, SvgLaneMid, SvgLaneTop, SvgLaneSupport },
  data: () => ({
    /** @type {LolChamp} */
    champ: null,
  }),
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    c() {
      return /** @type {QuestionCategory} */ this.category || {};
    },
    champIcon() {
      return this.champ?.imageUrl || '';
    },
    laneImageComponent() {
      if (this.c.groupName !== 'LOL_LANE') return '';
      const laneImages = {
        ALL: 'svg-lane-all',
        TOP: 'svg-lane-top',
        MID: 'svg-lane-mid',
        JUNGLE: 'svg-lane-jungle',
        AD_CARRY: 'svg-lane-bottom',
        SUPPORT: 'svg-lane-support',
      };
      return laneImages[this.c.value] || '';
    },
  },
  watch: {
    category: {
      deep: true,
      handler: 'onCategoryChange',
    },
  },
  methods: {
    async onCategoryChange(c = {}) {
      this.champ = await this.$services.coaching.getChampByCode(c?.value || 'Garen');
    },
  },
  beforeMount() {
    this.onCategoryChange(this.c);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[category] {
  .wh(50);.br(12);
  &.lane {
    @g: @gradients[@5];background-image: linear-gradient(@g[@degree], @g[@start], @g[@end] 100%);
    .flex;.flex-ai(center);.flex-jc(center);
    svg { .fill(#fff); }
    p {.c(white);.bold;.fs(20);}
  }
}
</style>
