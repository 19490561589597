<template>
  <div question-preview :class="{clickable}" @click="() => $emit('examine', boardWriteId)">
    <div class="top">
      <ul class="tag-list">
        <tag v-for="tag in tags" :key="tag">{{ tag }}</tag>
      </ul>
      <p class="created-time" v-if="hasAnswer">{{ answeredTime }} 답변 작성됨</p>
      <p class="created-time" v-else>{{ createdTime }} 질문 작성됨</p>
    </div>
    <div class="mid">
      <category-icon :category="category" />
      <div class="content-area">
        <h1 class="title">{{ title }}</h1>
        <p class="content-preview">{{ content }}</p>
      </div>
    </div>
    <hr class="divider" />
    <template v-if="hasAnswer">
      <div class="recent-answer">
        <div class="recent-answer-tag">최신답변</div>
        <p class="coach-nickname">{{ coachNickname }}</p>
      </div>
      <p class="answer-content">{{ answerContent }}</p>
      <div class="answer-coaches" v-show="hasMoreAnswer">
        <p>
          <span class="label">다른 코치 답변</span>
          <span class="count">{{ answerAmount }}</span>
        </p>
        <answer-avatars :thumbnail-coaches="thumbnailCoaches" />
      </div>
    </template>
    <template v-else>
      <p class="waiting-desc">코치님의 답변을 기다리고 있습니다</p>
    </template>
  </div>
</template>

<script>
import CategoryIcon from '@/views/components/coaching/question/CategoryIcon.vue';
import Tag from '@/views/components/coaching/Tag.vue';
import AnswerAvatars from '@/views/components/coaching/question/AnswerAvatars.vue';

export default {
  components: { AnswerAvatars, Tag, CategoryIcon },
  lexicon: 'coaching',
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    q() {
      return /** @type {PreviewQuestion} */this.question || {};
    },
    boardWriteId() {
      return this._question?.boardWriteId || '';
    },
    _question() {
      return /** @type {Question} */ this.q?.question || {};
    },
    category() {
      const [category] = this._question?.categories || [];
      return /** @type{QuestionCategory} */ category || {};
    },
    tags() {
      const categories = /** @type{QuestionCategory[]} */ this._question?.categories || [];
      return categories.map(c => c.text);
    },
    answeredTime() {
      const answeredTime = this.q?.previewAnswer?.representativeAnswer?.answer?.createdDatetime || 0;
      return this.$fromNow(answeredTime);
    },
    createdTime() {
      const createdTime = this.q?.question?.createdDatetime || 0;
      return this.$fromNow(createdTime);
    },
    title() {
      return this.q?.question?.title || '-';
    },
    recentAnswer() {
      return /** @type {PreviewAnswer} */ this.q?.previewAnswer?.representativeAnswer?.answer || {};
    },
    hasAnswer() {
      return !!this.recentAnswer?.boardCommentId;
    },
    coachNickname() {
      return this.q?.previewAnswer?.representativeAnswer?.coach?.user?.nickname || '';
    },
    answerContent() {
      return this.q?.previewAnswer?.representativeAnswer?.answer?.content || '';
    },
    hasMoreAnswer() {
      return this.q?.previewAnswer?.thumbnailCoaches?.length >= 1;
    },
    answerAmount() {
      const amount = this.q?.previewAnswer?.thumbnailCoaches?.length || 0;
      return `${amount}`;
    },
    thumbnailCoaches() {
      return this.q?.previewAnswer?.thumbnailCoaches || [];
    },
    content() {
      return this.q?.question?.content || '';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[question-preview] {.br(12);.p(32);.bgc(#fff);.noto;box-sizing: border-box;
  &.clickable { .pointer;
    &:not(:hover) {border: 1px solid #fff;}
    &:hover {border: 1px solid #8300ff; }
  }
  .top {.flex;.mb(16);}
  .tag-list {list-style: none;flex-grow: 1;overflow: hidden;.h(24);}
  .created-time {.fs(12);.lh(18);.c(@c-text-dark);text-align: right;}
  .mid {.mb(16);.flex;
    [category] {.mr(8);.min-w(50);}
    .content-area {.min-w(0)}
    h1.title {.c(#1f2226);.fs(18);.lh(27);.bold;.block;.ellipsis;}
    .content-preview {.block;.ellipsis;.c(@c-text-gray);.fs(14);.lh(21);}
  }
  .divider {border-top: solid 1px #ebebeb;.w(50);.mb(16);}
  .waiting-desc {.fs(12);.lh(18);.c(#a4a3ae) }
  .recent-answer {.flex;.mb(6);}
  .recent-answer-tag {.fs(12);.lh(18);.c(#2c81ff);.mr(11);.bgc(#f2f5ff);.p(1, 2, 1, 2);}
  .coach-nickname {.c(@c-title-black);.fs(12);.bold;.lh(18);}
  .answer-content {.c(#787781);.fs(14);.lh(21);
    // 특정 줄 수 넘어가면 말줄임표 표기하는 기능
    .rel;.h(21*2);overflow: hidden;
    &::before { content: '';text-align: right;.abs;.rb(0);.wh(50%, 1.2em);background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);}
  }
  .answer-coaches {.flex;.flex-ai(center);.mt(22);
    > p {.mr(8);}
    > p > span {.fs(12);.lh(12)}
    .label {.c(#191919);.mr(3)}
    .count {.c(#2c81ff);}
  }
}
// mobile
@media (@tp-down) {
  [question-preview] {.p(20);
    h1.title {.fs(16);.lh(24);}
    .content-preview {display: none !important;}
    .coach-nickname {.max-w(250);.ellipsis;}
  }
}
</style>
