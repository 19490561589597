import { ModalFilterChamp, ModalFilterLane } from '@/views/components/LazyLoadings';

/**
 * @type {import('vue').Component}
 * @mixin
 * */
export default {
  computed: {
    lolLane: {
      get() {
        return this?.$route?.query?.['lol-lane'] || '';
      },
      set(lane = '') {
        let query = this.getMergedQuery({ ['lol-lane']: lane });
        if (lane === '') query = this.getRemovedQuery(['lol-lane']);
        this.$router.push({ query });
      }
    },
    lolChamp: {
      get() {
        return this?.$route?.query?.['lol-champ'] || '';
      },
      set(champ = '') {
        let query = this.getMergedQuery({ ['lol-champ']: champ });
        if (champ === '') query = this.getRemovedQuery(['lol-champ']);
        this.$router.push({ query });
      }
    },
    hasLane() {
      return this.lolLane !== '';
    },
    hasChamp() {
      return this.lolChamp !== '';
    },
  },
  methods: {
    resetFilter() {
      const query = this.getRemovedQuery(['lol-lane', 'lol-champ']);
      this.$router.push({ query });
    },
    async setChamp() {
      try {
        this.lolChamp = await this.$modal(ModalFilterChamp, { defaultChampId: this.lolChamp });
      } catch (err) {
        console.error(err);
      }
    },
    async setLane() {
      try {
        this.lolLane = await this.$modal(ModalFilterLane, { defaultLane: this.lolLane });
      } catch (err) {
        console.error(err);
      }
    },
  }
};
