<template>
  <div answer-avatars>
    <Avatar v-for="(thumbnailCoach, i) in coaches" :key="`thumbnail-coach-${thumbnailCoach.coachId}-${i}`" :info="thumbnailCoach" :defaultImgType="'fruit'" userProfile />
    <img src="/img/coaching/more-avatar.svg" avatar v-if="hasMore" />
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';

export default {
  components: { Avatar },
  props: {
    /**
     * @description
     * 질문 조회시 답변 자료형에 있는 .thumbnailCoaches를 그대로 사용
     * */
    thumbnailCoaches: {
      type: Array,
      default: () => ([]),
    },
    maxCoach: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    cs() {
      return /** @type {thumbnailCoach[]} */ this.thumbnailCoaches;
    },
    hasMore() {
      return this.cs?.length > this.maxCoach;
    },
    coaches() {
      return this.cs.slice(0, this.maxCoach);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[answer-avatars] { .rel;.h(34);
  [avatar] {.wh(34);.abs;
    each(range(6), {
      &:nth-child(@{value}) {
        .l((@value - 1) * 25);.z(6 - @value);border: solid 1px #fff;
      }
    })
  }
}
</style>
